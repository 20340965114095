import React, {useMemo, useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Button, Card, Col, Dropdown,
    ProgressBar, Row, Table, ToggleButton,
    ButtonGroup, Tooltip, OverlayTrigger
} from 'react-bootstrap';
import {getColor} from 'helpers/utils';
import * as echarts from 'echarts/core';
import {PieChart} from 'echarts/charts';

import {GridComponent, TitleComponent, TooltipComponent} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Analyze from 'components/common/Analyze';
import CsvDownload from 'components/common/CsvDownload';
import JsonDownload from 'components/common/JsonDownload';
import {deferredCallback, hasKey, NullFunction, NullScroller} from 'utilities/Convenience';
import JourneyCsvData from 'utilities/JourneyCsvData';
import {consolidateForCategories} from 'utilities/ConsolidateForCategories';

import Sparkchart from 'components/common/Sparkchart';
import {parseDomain, ParseResultType} from "parse-domain";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer
]);

function JourneyJsonData(onOutcomeData, name, uuid, uuidToOutcomeName) {
    return async () => {
        return await onOutcomeData({name: name, uuid: uuid});
    };
}

export const OutcomeTooltip = (params) =>
    `<div class="outcome-tooltip"><strong>${params.data.name}:</strong> ${params.data.value}</div>`;

const getOptions = (data, radius, total) => ({
    color: data.map((item) => getColor(item.color)),

    tooltip: {
        padding: [7, 10],
        transitionDuration: 0,
        backgroundColor: getColor('100'),
        borderColor: getColor('300'),
        textStyle: {color: getColor('dark')},
        borderWidth: 1,
        formatter: OutcomeTooltip
    },
    series: [
        {
            name: total,
            type: 'pie',
            radius,
            avoidLabelOverlap: false,
            emphasis: {
                scale: false
            },
            itemStyle: {
                borderWidth: 2,
                borderColor: getColor('card-bg')
            },
            label: {
                show: true,
                position: 'center',
                formatter: '{a}',
                fontSize: 23,
                color: getColor('dark')
            },
            data
        }
    ]
});

function getCsvFileName(originalName, name) {
    const piecesOfName = originalName.split(':');
    return piecesOfName.reduce((value, piece, _, array) => {
        if (piece.toLowerCase() === name.toLowerCase()) {
            array.splice(0);
            return `${value}${piece}`;
        }
        return `${value}${piece}-`;
    }, '').replace(/\s/g, '');
}

function getBottommostChildren(outcomes) {
    return outcomes.reduce((accumulator, outcome) => {
        const {children} = outcome;
        let bottomChildren = [outcome];
        if (children) {
            bottomChildren = getBottommostChildren(children);
        }
        return [...accumulator, ...bottomChildren];
    }, []);
}

const SuperOutcome = ({
                          item,
                          index,
                          total,
                          onChange,
                          selectedOutcome,
                          maxPercentage,
                          analyzing,
                          onOutcomeCsv,
                          uuidToOutcomeName,
                          indent,
                          dataCounts,
                          ChildType,
                          showAbsoluteSparkData
                      }) => {
    const {name, originalName, color, value, children} = item;

    const [expanded, setExpanded] = useState(false);
    const prefix = 'outcome-';
    const fileName = getCsvFileName(originalName, name);

    const percentage = ((value * 100) / total).toFixed(0);
    const displayPercentage = (percentage / maxPercentage * 100).toFixed(0);
    const postfix = indent > 0 ? '-' + indent : '';

    const bottommostChildren = getBottommostChildren(children);

    let properties = {
        enabled: true,
        sparkData: item.series,
        lineColor: 'blue',
        areaColor: 'blue',
        classPrefix: 'test',
        dataCounts: dataCounts,
        showAbsoluteSparkData: showAbsoluteSparkData
    };

    return (<>
        <tr
            className={classNames(
                `outcome-${index}${postfix}`,
                'fw-semi-bold',
                'fs--2',
                `${index === '0' && 'mt-3'}`,
                'pe-1',
                'px-1',
                'me-1'
            )}
        >
            <td className="col-6">
                <p className="mb-1">
                    <button
                        aria-expanded={expanded}
                        eventkey={`${index}`}
                        onClick={() => setExpanded(!expanded)}
                        className={`outcome-${index}-button${postfix} outcome-button btn-sm-i-no-s accordion-button ` + (expanded ? 'expanded' : 'collapsed')}
                        style={{paddingLeft: `${indent}rem`}}
                    >
                        <FontAwesomeIcon
                            icon="circle"
                            className={`me-2 text-${color}`}
                        />
                        {name}
                    </button>
                </p>
            </td>
            <td className="align-middle">
                {expanded ? null : (
                    <Flex alignItems="center" className="pt-2">
                        <ProgressBar className={`${prefix}${index}-progress${postfix}`} now={displayPercentage}
                                     style={{width: '80px', height: 5}} variant="success"/>
                        <div className={`fw-semi-bold ms-3 ${prefix}${index}-percentage${postfix}`}>
                            {percentage}%{(analyzing) ? ' - (' + value.toString() + ')' : null}
                        </div>
                        {analyzing ? (<>
                            <CsvDownload
                                className={`ps-2 outcome-${index}-csv-download`}
                                headers={['Journey', 'Occurrences', 'Name', 'Action', 'Delta', 'Sequence', 'Steps away', 'Timestamp', 'Software Version', 'Device Model', 'Operating System Version', 'Category', 'Result', 'UUIDs', 'Tags', 'Detail', 'Identifier']}
                                getCsvData={bottommostChildren.map((child => JourneyCsvData(
                                    onOutcomeCsv, child.originalName,
                                    child.uuid, uuidToOutcomeName)))}
                                filename={fileName + '.csv'}
                            />
                            <JsonDownload
                                className={`ps-2 outcome-${index}-json-download`}
                                headers={['Journey', 'Occurrences', 'Name', 'Action', 'Delta', 'Sequence', 'Steps away', 'Timestamp', 'Software Version', 'Device Model', 'Operating System Version', 'Category', 'Result', 'UUIDs', 'Tags', 'Detail', 'Identifier']}
                                getJsonData={bottommostChildren.map((child => JourneyJsonData(
                                    onOutcomeCsv, child.originalName,
                                    child.uuid, uuidToOutcomeName)))}
                                filename={fileName + '.json'}
                            />
                            </>) : null
                        }
                    </Flex>)}
            </td>
            <td>
                <Sparkchart {...properties} />
            </td>
        </tr>
        {expanded ? (children.map((child, indexSub) => (
                <ChildType
                    item={child} onChange={onChange} index={`${index}-${indexSub}`} key={indexSub}
                    uuidToOutcomeName={uuidToOutcomeName}
                    onOutcomeCsv={onOutcomeCsv} total={total} maxPercentage={maxPercentage}
                    indent={indent + 1} analyzing={analyzing} selectedOutcome={selectedOutcome}
                    dataCounts={dataCounts}
                />))
        ) : null}
    </>);
};


SuperOutcome.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        children: PropTypes.array,
        series: PropTypes.array,
        originalName: PropTypes.string.isRequired,
    }),
    index: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedOutcome: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        uuid: PropTypes.string
    }),
    maxPercentage: PropTypes.number.isRequired,
    analyzing: PropTypes.bool,
    onOutcomeCsv: PropTypes.func.isRequired,
    uuidToOutcomeName: PropTypes.object.isRequired,
    indent: PropTypes.number,
    dataCounts: PropTypes.object.isRequired,
};

const Outcome = ({
                     item,
                     index,
                     total,
                     onChange,
                     selectedOutcome,
                     maxPercentage,
                     analyzing,
                     onOutcomeCsv,
                     uuidToOutcomeName,
                     indent = 0,
                     dataCounts,
                     showAbsoluteSparkData
                 }) => {
    const {name, color, value, uuid, children, originalName, osVersion} = item;
    let selectedClass = 'border border-info rounded';
    const prefix = 'outcome-';
    if (!selectedOutcome ||
        !(selectedOutcome.name.includes(name) &&
            selectedOutcome.uuid === uuid)) selectedClass = '';

    const fileName = getCsvFileName(originalName, name);
    const [showTooltip, setShowTooltip] = useState(false);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip"
                 onMouseEnter={() => setShowTooltip(true)}
                 onMouseLeave={() => setShowTooltip(false)}
                 {...props}>
            {name}
        </Tooltip>
    );

    const percentage = ((value * 100) / total).toFixed(0);
    const displayPercentage = (percentage / maxPercentage * 100).toFixed(0);
    const postfix = indent > 0 ? '-' + indent : '';

    let properties = {
        enabled: true,
        sparkData: item.series,
        lineColor: 'blue',
        areaColor: 'blue',
        hidden: false,
        classPrefix: 'test',
        dataCounts: dataCounts,
        showAbsoluteSparkData: showAbsoluteSparkData
    };

    if (children) {
        let childType = Outcome;
        let clone = item;

        return (
            <SuperOutcome
                item={clone} onChange={onChange} index={index} uuidToOutcomeName={uuidToOutcomeName}
                onOutcomeCsv={onOutcomeCsv} total={total} maxPercentage={maxPercentage} indent={indent}
                analyzing={analyzing} selectedOutcome={selectedOutcome}
                dataCounts={dataCounts} ChildType={childType}
            />
        );
    }

    return (
        <tr
            className={classNames(
                `outcome-${index}${postfix}`,
                'fw-semi-bold',
                'fs--2',
                `${index === '0' && 'mt-3'}`,
                selectedClass,
                'pe-1',
                'px-1',
                'me-1'
            )}
        >
            <td className="align-middle" style={{minWidth: '500px'}}>
                <span className="mb-1">
                    <OverlayTrigger
                        show={showTooltip}
                        placement="top"
                        delay={{show: 250, hide: 300}}
                        overlay={renderTooltip}
                    >
                        <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                            <Button
                                target="_blank"
                                variant="link"
                                size="sm"
                                className={`outcome-${index}-button${postfix} outcome-button`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    onChange({name: originalName, uuid: uuid});
                                }}
                                style={{
                                    paddingLeft: `${indent}rem`,
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'left'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon="circle"
                                    className={`me-2 text-${color}`}
                                />
                                {name}
                            </Button>
                        </span>
                    </OverlayTrigger>
                </span>
            </td>
            <td className="align-middle">
                <Flex alignItems="center">
                    <ProgressBar className={`${prefix}${index}-progress${postfix}`} now={displayPercentage}
                                 style={{width: '80px', height: 5}} variant="success"/>
                    <div className={`fw-semi-bold ms-3 ${prefix}${index}-percentage${postfix}`}>
                        {percentage}%{(analyzing) ? ' - (' + value.toString() + ')' : null}
                    </div>
                    {analyzing ? (<>
                        <CsvDownload
                            className={`ps-2 outcome-${index}-csv-download`}
                            headers={[
                                'Journey',
                                'Occurrences',
                                'Name',
                                'Action',
                                'Delta',
                                'Sequence',
                                'Steps away',
                                'Timestamp',
                                'Software Version',
                                'Device Model',
                                'Operating System Version',
                                'Category',
                                'Result',
                                'UUIDs',
                                'Tags',
                                'Detail',
                                'Identifier'
                            ]}
                            getCsvData={JourneyCsvData(onOutcomeCsv,
                                originalName, uuid, uuidToOutcomeName)}
                            filename={fileName + '.csv'}
                        />
                        <JsonDownload
                            className={`ps-2 outcome-${index}-json-download`}
                            headers={[
                                'Journey',
                                'Occurrences',
                                'Name',
                                'Action',
                                'Delta',
                                'Sequence',
                                'Steps away',
                                'Timestamp',
                                'Software Version',
                                'Device Model',
                                'Operating System Version',
                                'Category',
                                'Result',
                                'UUIDs',
                                'Tags',
                                'Detail',
                                'Identifier'
                            ]}
                            getJsonData={JourneyJsonData(onOutcomeCsv,
                                originalName, uuid, uuidToOutcomeName)}
                            filename={fileName + '.json'}
                        />
                        </>) : null
                    }
                </Flex>
            </td>
            <td>
                <Sparkchart {...properties} />
            </td>
        </tr>
    );
};


Outcome.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired,
        children: PropTypes.array,
        series: PropTypes.array,
        originalName: PropTypes.string.isRequired
    }),
    index: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedOutcome: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        uuid: PropTypes.string
    }),
    maxPercentage: PropTypes.number.isRequired,
    analyzing: PropTypes.bool,
    onOutcomeCsv: PropTypes.func.isRequired,
    uuidToOutcomeName: PropTypes.object.isRequired,
    indent: PropTypes.number,
    dataCounts: PropTypes.object.isRequired,
};

const Outcomes = ({
                      data,
                      radius,
                      onChange = NullFunction,
                      selectedOutcome,
                      filterMap,
                      onFilter = NullFunction,
                      onAnalyze = NullFunction,
                      analyzing,
                      AnalyzeComponent = Analyze,
                      timestampFilterDebounce,
                      onOutcomeCsv,
                      csvDataDelay = 500,
                      uuidToOutcomeName,
                      dataCounts,
                      spinning,
                      variants,
                      sites
                  }) => {
    const bottom = useRef(new NullScroller());
    const topRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [consolidatedData, setConsolidatedData] = useState([]);
    const [showAbsoluteSparkData, setAbsoluteSparkData] = useState(false);
    const [sparkDataMode, setSparkDataMode] = useState("Normalized Sparkcharts");
    const [variantFilter, setVariantFilter] = useState('All');
    const [siteFilter, setSiteFilter] = useState('All');

    useEffect(() => {
        if (!analyzing) {
            setAbsoluteSparkData(false);
        }
    }, [analyzing]);


    useMemo(() => {
        setConsolidatedData(consolidateForCategories(data, false));
        if (!selectedOutcome) return;
        const containsSelectedOutcome = data.reduce((previous, item) => {
            if (previous) return previous;
            return item.name === selectedOutcome.name || selectedOutcome.name === '';
        }, false);
        if (!containsSelectedOutcome && selectedOutcome.name !== '') onChange({name: '', uuid: ''}, NullFunction);
    }, [data, onChange, selectedOutcome]);

    if (data.length < 1 && !hasKey('name', filterMap.timeFilter)) {
        return spinning('outcomes-spinner');
    }
    const total = consolidatedData.reduce((acc, val) => val.value + acc, 0);
    const maxPercentage = consolidatedData && consolidatedData.length > 1 ? consolidatedData[0].value / total * 100 : 0;

    function showOutcomes() {
        const numberToShow = 10;

        function mapToJsx(outcomesMap, startAt = 0) {
            return outcomesMap.map((item, index) => (
                <Outcome
                    item={item}
                    index={`${index + startAt}`}
                    key={index + startAt}
                    total={total}
                    onChange={(newSelectedOutcome) =>
                        onChange(newSelectedOutcome, deferredCallback(
                            () => bottom.current.scrollIntoView({behavior: 'smooth', block: 'start'})
                        ))
                    }
                    selectedOutcome={selectedOutcome}
                    maxPercentage={maxPercentage}
                    showAbsoluteSparkData={showAbsoluteSparkData}
                    analyzing={analyzing}
                    onOutcomeCsv={onOutcomeCsv}
                    uuidToOutcomeName={uuidToOutcomeName}
                    dataCounts={dataCounts}
                />
            ));
        }

        if (consolidatedData.length > numberToShow && !analyzing) {
            const topTen = consolidatedData.slice(0, numberToShow);
            const remaining = consolidatedData.slice(numberToShow);

            const jsxBlob1 = mapToJsx(topTen);
            const jsxBlob2 = (<>
                <tr>
                    <td>
                        <button
                            aria-expanded={expanded}
                            eventkey="0"
                            onClick={() => setExpanded(!expanded)}
                            className={'remaining-outcomes-button b7 accordion-button ' + (expanded ? 'expanded' : 'collapsed')}
                        >
                            Remaining Outcomes {'(' + (consolidatedData.length - 10).toString() + ')'}
                        </button>
                    </td>
                </tr>
                {expanded ? mapToJsx(remaining, numberToShow) : null}
            </>);

            return (<>{jsxBlob1}{jsxBlob2}</>);
        }
        return mapToJsx(consolidatedData);
    }

    const csvData = data.map((item) => ([...item.name.split(':'), item.value]));

    function variantFilterFunction(values, key, filter) {
        if (filter === 'All') return values;
        let filtered = [];
        values.forEach((element) => {
            let newElement = {...element};
            const series = element.series;
            let filteredSeries = []

            const tags = element.tags;
            let filteredTags = {};

            const platforms = Array.isArray(element.platforms) ? element.platforms[0] : element.platforms;
            let filteredPlatforms = {};

            for (const [key, value] of Object.entries(tags)) {
                if (value.includes(filter)){
                    filteredSeries.push(key)
                    filteredTags[key] = value;
                    filteredPlatforms[key] = platforms[key] ? platforms[key] : null;
                }
            }

            const value = filteredSeries.length;
            if (value === 0) return;
            newElement[key] = value;
            newElement.series = filteredSeries;
            newElement.tags = filteredTags;
            (key === 'value') ? newElement.platforms[0] = filteredPlatforms :
                                newElement.platforms = filteredPlatforms;
            filtered.push(newElement);
        });
        filtered.sort((a, b) => {
            if (a[key] > b[key]) return -1;
            if (a[key] < b[key]) return 1;
            return 0;
        });
        return filtered;
    }

    function siteFilterFunction(values, key, filter) {
        if (filter === 'All') return values;
        let filtered = [];
        values.forEach((element) => {
            let newElement = {...element};
            const {url} = newElement;
            if (url) {
                const parsed = new URL(url);
                const parseResult = parseDomain(
                    // This should be a string with basic latin letters only.
                    // More information below.
                    parsed.hostname,
                );
                // Check if the domain is listed in the public suffix list
                if (parseResult.type === ParseResultType.Listed) {
                    const {subDomains, domain, topLevelDomains} = parseResult;
                    let subDomain = ''
                    if (subDomains.length > 0) {
                        subDomain = subDomains[0];
                    } else {
                        subDomain = 'www';
                    }
                    if (subDomain === filter) {
                        filtered.push(newElement);
                    }
                } else {
                    // Read more about other parseResult types below...
                }
            }
        });
        filtered.sort((a, b) => {
            if (a[key] > b[key]) return -1;
            if (a[key] < b[key]) return 1;
            return 0;
        });
        return filtered;
    }

    return (
        <Card className="outcomes h-md-100" ref={topRef}>
            <Card.Header className="pb-0">
                <Row className="align-items-center">
                    <Col xs="auto" className="align-middle">
                        <h6 className="h6-vertical-align mt-0 mb-0">Outcomes</h6>
                    </Col>
                    <AnalyzeComponent
                        initialFilterMap={filterMap}
                        timestampFilterDebounce={timestampFilterDebounce}
                        onFilter={onFilter}
                        onAnalyze={onAnalyze}
                    />
                    {analyzing ? (
                        <Col xs="auto" className="px-1">
                            <Dropdown as={ButtonGroup} className="filter-button filter-dropdown">
                                <Dropdown.Toggle
                                    variant="falcon-default align-middle pt-0 pb-0 b7 filter-dropdown-toggle">
                                    {"Variant: " + variantFilter}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="py-2">
                                    {variants.map((item) => item && (
                                        <Dropdown.Item as="button"
                                                       active={variantFilter === item}
                                                       className={"filter-variant-" + item}
                                                       key={"filter-variant-" + item}
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           const filterFunctionOutcomes = (outcomes) => variantFilterFunction(outcomes, 'value', item);
                                                           onFilter('variants-outcomes', filterFunctionOutcomes, "outcomes");
                                                           const filterFunctionJourneys = (journeys) => variantFilterFunction(journeys, 'occurrences', item);
                                                           onFilter('variants-journeys', filterFunctionJourneys, "journeys");
                                                           setVariantFilter(item)
                                                       }}
                                        >{item}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>) : null
                    }
                    {analyzing && sites.length > 1 ? (
                        <Col xs="auto" className="px-1">
                            <Dropdown as={ButtonGroup} className="filter-button filter-dropdown">
                                <Dropdown.Toggle
                                    variant="falcon-default align-middle pt-0 pb-0 b7 filter-dropdown-toggle">
                                    {"Site: " + siteFilter}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="py-2">
                                    {sites.map((item) => item && (
                                        <Dropdown.Item as="button"
                                                       active={siteFilter === item}
                                                       className={"filter-site-" + item}
                                                       key={"filter-site-" + item}
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           const filterFunctionOutcomes = (outcomes) => siteFilterFunction(outcomes, 'value', item);
                                                           onFilter('site-outcomes', filterFunctionOutcomes, "outcomes");
                                                           const filterFunctionJourneys = (journeys) => siteFilterFunction(journeys, 'occurrences', item);
                                                           onFilter('site-journeys', filterFunctionJourneys, "journeys");
                                                           setSiteFilter(item)
                                                       }}
                                        >{item}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>) : null
                    }
                    {analyzing ? (
                        <Col xs="auto" className="px-1">
                            <Dropdown as={ButtonGroup} className="sparkchart-dropdown">
                                <Dropdown.Toggle
                                    variant="falcon-default align-middle pt-0 pb-0 b7 sparkchart-dropdown-toggle">
                                    {sparkDataMode}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="py-2">
                                    {!showAbsoluteSparkData ?
                                        <Dropdown.Item as="button"
                                                       className="filter-menu-absolute"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           setSparkDataMode('Absolute Sparkcharts');
                                                           setAbsoluteSparkData(true);
                                                       }}
                                        >Absolute Sparkcharts</Dropdown.Item> : null
                                    }
                                    {showAbsoluteSparkData ?
                                        <Dropdown.Item as="button"
                                                       className="filter-menu-normalized"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           setSparkDataMode('Normalized Sparkcharts');
                                                           setAbsoluteSparkData(false);
                                                       }}
                                        >Normalized Sparkcharts</Dropdown.Item> : null
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>) : null
                    }
                    {(() => {
                        if (analyzing) return (<Col>
                            <JsonDownload
                                className="float-end outcomes-json-download"
                                headers={['Category', 'Result', 'Name', 'Count']}
                                getJsonData={[async () => {
                                    await new Promise(r => setTimeout(r, csvDataDelay));
                                    return data;
                                }]}
                                filename={'xenon-view-outcomes.json'}
                                style={{verticalAlign: 'top'}}
                            />
                            <CsvDownload
                                className="px-1 float-end outcomes-csv-download"
                                headers={['Category', 'Result', 'Name', 'Count']}
                                getCsvData={[async () => {
                                    await new Promise(r => setTimeout(r, csvDataDelay));
                                    return csvData;
                                }]}
                                filename={'xenon-view-outcomes.csv'}
                                style={{verticalAlign: 'top'}}
                            />
                        </Col>);
                        return null;
                    })()}
                </Row>
            </Card.Header>
            <Card.Body className="pt-0">
                <Row className="justify-content-between g-0">
                    <Col xs={10} sm={10} className="pe-2 pb-1">
                        <Table className="table-fixed" size="sm" borderless={true} style={{tableLayout: 'fixed'}}>
                            <tbody>
                            {showOutcomes()}
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs="auto">
                        <div className="outcome-chart-location ps-0">
                            {((showChart) => {
                                if (showChart) {
                                    return (<BasicECharts
                                        echarts={echarts}
                                        options={getOptions(consolidatedData, radius, total)}
                                        style={{width: '6.625rem', height: '6.625rem'}}
                                        className={'outcome-chart'}
                                    />);
                                }
                                return (<h3 className="pt-3 word-wrap no-data-message">No Outcomes To Show</h3>);
                            })(total !== 0)}
                        </div>
                    </Col>
                </Row>
            </Card.Body>
            <span ref={bottom}/>
        </Card>
    )
        ;
};


Outcomes.propTypes = {
    data: PropTypes.array.isRequired,
    radius: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    selectedOutcome: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        uuid: PropTypes.string
    }),
    filterMap: PropTypes.object.isRequired,
    onFilter: PropTypes.func,
    AnalyzeComponent: PropTypes.func,
    timestampFilterDebounce: PropTypes.number,
    onAnalyze: PropTypes.func,
    analyzing: PropTypes.bool.isRequired,
    onOutcomeCsv: PropTypes.func.isRequired,
    csvDataDelay: PropTypes.number,
    uuidToOutcomeName: PropTypes.object.isRequired,
    dataCounts: PropTypes.object,
    spinning: PropTypes.func.isRequired
};

Outcomes.defaultProps = {
    filterMap: {}
};

export default Outcomes;
